import { Component, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AnimationOptions, LottieComponent } from "ngx-lottie";
import { SignInService } from "../sign-in/sign-in.service";

@Component({
  selector: "app-sign-in-required",
  standalone: true,
  imports: [TranslateModule, LottieComponent],
  templateUrl: "./sign-in-required.component.html",
  styleUrl: "./sign-in-required.component.scss",
})
export class SignInRequiredComponent {
  signInRequiredLoader: AnimationOptions = {
    path: "../../../assets/images/signInRequired.json",
  };
  signInRequiredLoaderStyle: Partial<CSSStyleDeclaration> = {
    height: "50vh",
  };
}
