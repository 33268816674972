export enum ToastType {
    Success,
    Error,
    Info,
    Warning,
    // Permission,
    // Notification,
}

export interface ToastModel {
    Title?: string;
    Text?: string;
    ToastType?: ToastType;
    RemovePreviousAlerts?: boolean;
    OnActionCallback?: () => void;
    OnHiddenCallback?: () => void;
    OnShownCallback?: () => void;
    OnTapCallback?: () => void;
}
