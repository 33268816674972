import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { IGlobalErrorHandlerService } from "./global-error-handler.service.interface";

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandlerService implements IGlobalErrorHandlerService {
  constructor(
    private router: Router,
    private zone: NgZone,
  ) {}

  /**
   * Global error handler
   */
  handleError(error: any): void {
    // Optional: Log error to the console
    console.error("Error captured by GlobalErrorHandler:", error);

    // Log the error using Sentry
    Sentry.captureException(error);

    // Optional: Redirect to a global error page
    // this.zone.run(() => this.router.navigate(['/error']));
  }
}
