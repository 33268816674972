import { inject, Injectable } from "@angular/core";
import { CommonService } from "../components/common/common.service";
import { Language } from "../helpers/enums/language.enums";
import english from "../../assets/i18n/en.json";
import arabic from "../../assets/i18n/ar.json";
import { TranslateService } from "@ngx-translate/core";
import { ToastModel, ToastType } from "../shared/models/view-models/ToastModel";
import { ToastrService } from "ngx-toastr";
import {
  Organization,
  OrganizationDetailsByOrgURL,
} from "../shared/models/view-models/organization";
import { ResponseCode, ResponseHelper } from "../helpers/response-helper";
import { UserRoleType } from "../helpers/enums/user-role.enums";
import { catchError, firstValueFrom, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppService extends CommonService {
  public translateService: TranslateService = inject(TranslateService);
  private toastrService: ToastrService = inject(ToastrService);

  setDefaultLanguage() {
    const setLanguage = this.cookieService.get("translate_language");
    switch (setLanguage) {
      case Language.ARABIC: {
        // Retrieve the language using `cookieService`. When the language is changed, ensure the selected language is displayed, and switch the direction from LTR to RTL accordingly.
        this.translateService.setTranslation(Language.ARABIC, arabic);
        this.translateService.setDefaultLang(Language.ARABIC);
        const html = document.querySelector("html[dir]") as HTMLElement;
        html.dir = "rtl";
        break;
      }
      case Language.ENGLISH: {
        this.translateService.setTranslation(Language.ENGLISH, english);
        this.translateService.setDefaultLang(Language.ENGLISH);
        break;
      }
      default: {
        // Using Default english translation
        this.translateService.setTranslation(Language.ENGLISH, english);
        this.translateService.setDefaultLang(Language.ENGLISH);
        break;
      }
    }
  }

  registerToastListener() {
    this.eventEmitterService.toastManipulation.subscribe(
      (toastData: ToastModel) => {
        let toastInstance: any;
        if (toastData.RemovePreviousAlerts) {
          this.toastrService.clear();
        }
        const options = { opacity: 0.95, progressBar: true };
        switch (toastData.ToastType) {
          case ToastType.Success: {
            toastInstance = this.toastrService.success(
              toastData.Text || "Your request has been processed.",
              toastData.Title || "Great!",
              options,
            );
            break;
          }
          case ToastType.Error: {
            toastInstance = this.toastrService.error(
              toastData.Text || "Something went wrong! Please try again.",
              toastData.Title || "Error!",
              options,
            );
            break;
          }
          case ToastType.Info: {
            toastInstance = this.toastrService.info(
              toastData.Text || "Data updated!",
              toastData.Title,
              options,
            );
            break;
          }
          case ToastType.Warning: {
            toastInstance = this.toastrService.warning(
              toastData.Text || "Something went wrong!",
              toastData.Title || "Oops!",
              options,
            );
            break;
          }
        }

        if (toastData.OnActionCallback) {
          toastInstance.onAction.subscribe(() => {
            toastData.OnActionCallback?.();
          });
        }

        if (toastData.OnHiddenCallback) {
          toastInstance.onHidden.subscribe(() => {
            toastData.OnHiddenCallback?.();
          });
        }

        if (toastData.OnShownCallback) {
          toastInstance.onShown.subscribe(() => {
            toastData.OnShownCallback?.();
          });
        }

        if (toastData.OnTapCallback) {
          toastInstance.onTap.subscribe(() => {
            toastData.OnTapCallback?.();
          });
        }
      },
    );
  }

  /**
   * Fetches organization details by subdomain
   */
  getOrganizationDetailsBySubdomain(subDomain: string) {
    if (subDomain) {
      this.getOrganizationDetailsByOrgURL(subDomain).subscribe({
        next: async (response: ResponseHelper) => {
          if (
            !response ||
            (response && response.Status == ResponseCode.ERROR)
          ) {
            //-- To return 404 error
            this.eventEmitterService.subDomainDataAltered();
            return;
          }
          this.organizationDetails =
            response.Payload as OrganizationDetailsByOrgURL;
          if (
            this.organizationDetails &&
            this.organizationDetails.OrganizationUUID
          ) {
            this.cookieService.delete("subDomain");
            this.cookieService.set("subDomain", subDomain);
            const existingOrgRecord: Organization =
              await this.dbService.getRecord(
                "Organization",
                "OrganizationUUID",
                this.organizationDetails.OrganizationUUID,
              );
            if (!existingOrgRecord?.OrganizationUUID) {
              this.dbService.truncateTable("Organization");
              this.dbService.addRecord("Organization", {
                OrganizationDescription:
                  this.organizationDetails.OrganizationDescription || "",
                OrganizationLogo: this.organizationDetails.OrganizationLogo,
                OrganizationName: this.organizationDetails.OrganizationName,
                OrganizationShortLogo:
                  this.organizationDetails.OrganizationShortLogo,
                OrganizationUUID: this.organizationDetails.OrganizationUUID,
                OrganizationTitleText:
                  this.organizationDetails.OrganizationTitleText,
              });
            } else {
              this.dbService.updateRecordWithWhere(
                "Organization",
                "OrganizationUUID",
                existingOrgRecord.OrganizationUUID,
                {
                  OrganizationDescription:
                    this.organizationDetails.OrganizationDescription || "",
                  OrganizationLogo: this.organizationDetails.OrganizationLogo,
                  OrganizationName: this.organizationDetails.OrganizationName,
                  OrganizationShortLogo:
                    this.organizationDetails.OrganizationShortLogo,
                  OrganizationUUID: this.organizationDetails.OrganizationUUID,
                  OrganizationTitleText:
                    this.organizationDetails.OrganizationTitleText,
                },
              );
            }
            this.eventEmitterService.subDomainDataAltered(
              this.organizationDetails,
            );
          } else {
            //-- To return 404 error
            this.eventEmitterService.subDomainDataAltered();
          }
        },
        error: () => {
          //-- To return 404 error
          this.eventEmitterService.subDomainDataAltered();
        },
      });
    }
  }

  /**
   * Fetches the routes for Signed In  user.
   * @param userUUID: User UUID of Signed in user
   * @param organizationUUID: Organization UUID to which user is signed in
   * @returns void
   */
  async getRoutesAndModulesForSignedInUser(
    userUUID: string | null,
    organizationUUID: string | null,
  ) {
    const response: ResponseHelper | undefined = await firstValueFrom(
      this.getOrganizationBasedModules(userUUID, organizationUUID).pipe(
        catchError(() => {
          return of(undefined); // Return a fallback value or observable
        }),
      ),
    );
    return response;
  }

  /**
   * Fetches the routes for guest user.
   * @param organizationUUID: Organization UUID to which user is a guest
   * @returns void
   */
  async getRoutesAndModulesForGuest(
    organizationUUID: string | null,
  ): Promise<ResponseHelper | undefined> {
    const response: ResponseHelper | undefined = await firstValueFrom(
      this.getURAndOrganizationBasedModules(
        UserRoleType.GUEST,
        organizationUUID,
      ).pipe(
        catchError(() => {
          return of(undefined); // Return a fallback value or observable
        }),
      ),
    );
    return response;
  }

  /**
   * Fetches the routes for visitor user.
   * @param organizationUUID: Organization UUID to which user is visiting
   * @returns void
   */
  async getRoutesAndModulesForVisitor(
    organizationUUID: string | null,
  ): Promise<ResponseHelper | undefined> {
    const response: ResponseHelper | undefined = await firstValueFrom(
      this.getURAndOrganizationBasedModules(
        UserRoleType.VISITOR,
        organizationUUID,
      ).pipe(
        catchError(() => {
          return of(undefined); // Return a fallback value or observable
        }),
      ),
    );
    return response;
  }
}
